/* Provide sufficient contrast against white background */
a {
    color: #ff6a00;
}

.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
    box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1d1f4f;
    border-color: transparent;
}

    .btn-primary:hover {
        color: #fff;
        background-color: #2a2e9c;
        border-color: transparent;
    }

    .btn-primary:disabled {
        color: #fff;
        background-color: #4a4c72;
        border-color: transparent;
    }

.btn-secondary {
    color: #fff;
    background-color: #9c731a;
    border-color: transparent;
}

    .btn-secondary:hover {
        color: #fff;
        background-color: #af8f48;
        border-color: transparent;
    }

.card {
    border: none;
    border-radius: 6px;
    background-color: #1d1f4f;
    color: #fff;
    margin-bottom: 16px;
}

.card-clickable:hover {
    border: none;
    border-radius: 6px;
    background-color: #4a4c72;
}

.form-group {
    margin-bottom: 0.5rem;
}

.leaflet-container {
height: 100%;
    width: 100%;
}

.navbar-brand {
    height: 47px;
    padding: 2px;
}

ul.no-bullets {
    list-style-type: none; /* Remove bullets */
}
